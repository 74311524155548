.button-wrapper {
    display: flex;

    &.password {
        justify-content: flex-end;
        padding-bottom: 1rem;
        margin: 1rem 0;
        border-bottom: 1px solid #707070;
    }

    &.multiples {
        justify-content: flex-end;
        margin-top: 1rem;
        
        > button {
            margin-left: 1rem;
        }
    }

    &.user-search {
        align-items: center;
        height: 100%;
        padding-top: 8px;

        > button {
            margin-right: 1rem;
        }
    }
}

.MuiDataGrid-root {
    border: 1px solid #707070 !important;

    .MuiDataGrid-columnSeparator {
        color: #707070 !important;
    }

    .MuiDataGrid-columnsContainer, .MuiDataGrid-cell {
        border-bottom: 1px solid  #707070 !important;
    }

    .MuiDataGrid-window {
        overflow-y: hidden !important;

        &::-webkit-scrollbar {
            height: 10px;
            width: 10px;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:horizontal {
            background: #14B8AE;
            border-radius: 10px;
        }
    }
}

.permissions-tree-content {
    height: 385px;
    width: 100%;
    margin-top: 1rem;
    position: relative;
    border: 1px solid #707070;
    border-radius: 4px;

    > label {
        position: absolute;
        top: -8px;
        left: 7px;
        background-color: #F5F5F5;
        padding: 0 7px;
    }

    .permissions-tree-wrapper {
        padding: 1rem 0.5rem;
    }
}