.request-popup .leaflet-popup-content-wrapper {
    border-radius: 0px !important;
    background-color: #F5F5F5;
}

.marker-wrapper {
        

        > ul {
        list-style: none;
        padding-inline-start: 0;

        > li {
            font-size: 1rem;
            margin-bottom: 0.7rem;

            > span {
                font-weight: bold;
            }
        }
    }
}
