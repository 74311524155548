.dropzone {
    padding: 1rem;
    margin: 1.5rem 0 1rem 0;
    background-color: #BFBFBF;
    cursor: pointer;

    .dropzone-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1rem;

        .dropzone-h2 {
            font-size: 1rem;
            font-weight: 500;
            color: white;
        }
    }
}

.expand {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    color: #707070;

    .expand-icon {
        transition: all .35s;
        -moz-transition: all .35s;
        -webkit-transition: all .35s;
        -o-transition: all .35s;

        &.open {
            transform: rotate(180deg);
            transition: all .35s;
            -moz-transition: all .35s;
            -webkit-transition: all .35s;
            -o-transition: all .35s;
        }
    }
}