.login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: url('/assets/Bg-login.jpg');
    background-position: absolute;
}/*
  

    .box {
        &.form {
            display: flex;
            flex-direction: column;
            //justify-content: center;
            align-items: flex-start;
           // flex: 1 1 50%;
            padding: 32px;
            
          
           border-radius: 8px;
           margin-bottom: 20px;
            
           flex: none;
            //order: 1;
        

        }
    }

    .signature {

        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-grow: 0;
        background: red;
        
        > img {
            padding-top: 3rem;
        }

    }

}*/
