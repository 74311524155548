.filter-form-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;

    .buttons {
        display: flex;
        justify-content: space-between;
        margin: 0.5rem -0.5rem 0 -0.5rem;
        margin-bottom: 10px;

        > button {
            margin: 0.5rem;
        }

    }
}