.grid-search-input {
    margin-bottom: 0.313rem !important;
}

.table-container {
    border: 1px solid #707070 !important;
    border-collapse: separate !important;
    border-radius: 4px !important;
}

.MuiTableSortLabel-icon {
    margin-right: 0 !important;
}