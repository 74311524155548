.permissions-checkbox-field-wrapper {
    border: 1px solid;
    border-color: #707070;
    border-radius: 4px;
    padding: 1rem;
    margin: 0.5rem 0;
    position: relative;

    .permissions-checkbox-tree-wrapper {
        max-height: 250px;
        overflow-y: scroll;
        margin: -0.5rem -1rem -1rem 0;
    }

    > span {
        position: absolute;
        top: -8px;
        left: -22px;
        color: #3A3A3A;
        font-weight: 500;
        background-color: #F5F5F5;
        transform: translate(14px, -6px) scale(0.75);
        padding: 0 0.5rem;
    }
}