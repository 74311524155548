form {
    width: 100%;
}

.button-wrapper {
    display: flex;

    &.multiples {
        justify-content: flex-end;
        margin-top: 1rem;
        
        > button {
            margin-left: 1rem;
        }
    }
}