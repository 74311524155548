
.boxLogo{    
    width: 203px;
    height: 64px;
    flex: none;
    padding: 0 25% ;
    order: 0;
    margin-bottom: 20px;
    flex-grow: 0;
}

.logoclass{
    
       max-width: 203px;

        path {
            fill: #FFFFFF;
        }
}
    
.login-form {
    
    display: flex;
    flex-direction: column;
    align-items: flex-start   ;
    margin: 20px;
    width: 420px;
    height: 220px;
    

    background: #FFFFFF;
    border-radius: 8px;

    box-sizing: initial;

    
    .inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
        width: 95%;
        height: 56px;
        padding-left: 10px;
        

    }
        
        .options {
            width: 596px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 10px 195px;
            > a > span {
                font-weight: 500;
            }
            
        }
    
    

    .button {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        padding: 115px 0px;
        padding-left: 10px;
        

        width: 101px;
        height: 32px;
        
        border-radius: 4px;


    

    }
    .button img{
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 2px 2px;
        color: #FFFFFF;
        box-sizing: initial;
       
    }
}