.checkbox-field-wrapper {
    border: 1px solid;
    border-color: #707070;
    border-radius: 4px;
    padding: 1rem 1rem 0.625rem 1rem;
    margin: 1rem 0 0.5rem 0;
    position: relative;

    > span {
        position: absolute;
        top: -8px;
        left: -22px;
        color: #3A3A3A;
        font-weight: 500;
        background-color: #F5F5F5;
        transform: translate(14px, -6px) scale(0.75);
        padding: 0 0.5rem;
    }

    .tree-wrapper {
        padding-bottom: 0.5rem;
        position: relative;
        overflow: hidden;
        max-height: 1.7rem;
        transition: max-height 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        -moz-transition: max-height 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        -webkit-transition: max-height 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        -o-transition: max-height 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        .expand {
            position: absolute;
            right: 0;
            top: 0;
            padding: 0;
            color: #707070;
    
            .expand-icon {
                transition: all .35s;
                -moz-transition: all .35s;
                -webkit-transition: all .35s;
                -o-transition: all .35s;
    
                &.open {
                    transform: rotate(180deg);
                    transition: all .35s;
                    -moz-transition: all .35s;
                    -webkit-transition: all .35s;
                    -o-transition: all .35s;
                }
            }
        }

        &.open {
            max-height: 100vh !important;
            transition: all .6s;
            -moz-transition: all .6s;
            -webkit-transition: all .6s;
            -o-transition: all .6s;
        }
    }

    .rct-collapse {
        display: none;
    }

    .rct-collapse,
    .rct-checkbox,
    .rct-node-icon {
        padding: 0 0.5rem 0 0 !important;
    }

    .rct-text {
        label {
            margin-bottom: 0.1rem;
        }
    }
}
