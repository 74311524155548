$rct-icon-color: #33c !default;
$rct-label-hover: rgba($rct-icon-color, .1) !default;
$rct-label-active: rgba($rct-icon-color, .15) !default;
$rct-clickable-hover: rgba($rct-icon-color, .1) !default;
$rct-clickable-focus: rgba($rct-icon-color, .2) !default;

.react-checkbox-tree {
  display: flex;
  flex-direction: row-reverse;
  font-size: 1rem;

  > ol {
    flex: 1 1 auto;
  }

  ol {
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    ol {
      padding-left: 24px;
    }
  }

  button {
    line-height: normal;
    color: inherit;

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .rct-bare-label {
    cursor: default;
  }

  label {
    margin-bottom: 0;
    cursor: pointer;

    &:hover {
      background: $rct-label-hover;
    }

    &:active {
      background: $rct-label-active;
    }
  }

  &:not(.rct-native-display) input {
    display: none;
  }

  &.rct-native-display input {
    margin: 0 5px;
  }

  .rct-icon {
    display: inline-block;
    text-align: center;
    text-rendering: auto;
    font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
  }
}

.rct-disabled {
  > .rct-text > label {
    opacity: .75;
    cursor: not-allowed;

    &:hover {
      background: transparent;
    }

    &:active {
      background: transparent;
    }
  }
}

.rct-text {
  display: flex;
  align-items: center;
}

.rct-options {
  flex: 0 0 auto;
  margin-left: .5rem;
  text-align: right;
}

.rct-option {
  opacity: .75;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0 4px;
  font-size: 18px;

  &:hover {
    opacity: 1;
  }

  + .rct-option {
    margin-left: 2px;
  }
}

.rct-collapse,
.rct-checkbox,
.rct-node-icon {
  padding: 0 5px;

  * {
    display: inline-block;
    margin: 0;
    width: 14px;
  }
}

.rct-collapse {
  border: 0;
  background: none;
  line-height: normal;
  color: inherit;
  font-size: 12px;

  &.rct-collapse-btn {
    cursor: pointer;
  }

  > .rct-icon-expand-close {
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }
}

.rct-checkbox {
  .rct-native-display & {
    display: none;
  }
}

.rct-node-clickable {
  cursor: pointer;

  &:hover {
    background: $rct-clickable-hover;
  }

  &:focus {
    outline: 0;
    background: $rct-clickable-focus;
  }
}

.rct-node-icon {
  display: none;
  color: $rct-icon-color;
}

.rct-title {
  padding: 0 5px;
}

// Font Awesome 4 style
.rct-icons-fa4 {
  .rct-icon-expand-close::before {
    content: "\f054";
  }

  .rct-icon-expand-open::before {
    content: "\f078";
  }

  .rct-icon-uncheck::before {
    content: "\f096";
  }

  .rct-icon-check::before {
    content: "\f046";
  }

  .rct-icon-half-check::before {
    opacity: .5;
    content: "\f046";
  }

  .rct-icon-leaf::before {
    content: "\f016";
  }

  .rct-icon-parent-open::before {
    content: "\f115";
  }

  .rct-icon-parent-close::before {
    content: "\f114";
  }

  .rct-icon-expand-all::before {
    content: "\f0fe";
  }

  .rct-icon-collapse-all::before {
    content: "\f146";
  }
}

// Font Awesome 5 style
.rct-icons-fa5 {
  .rct-icon-expand-close::before,
  .rct-icon-expand-open::before,
  .rct-icon-uncheck::before,
  .rct-icon-check::before,
  .rct-icon-half-check::before {
    font-size: 1rem;
  }

  .rct-icon-expand-close::before {
    font-weight: 900;
    content: "\f067";
  }

  .rct-icon-expand-open::before {
    font-weight: 900;
    content: "\f068";
  }

  .rct-icon-uncheck::before {
    content: "\f0c8";
    color: #707070;
  }

  .rct-icon-check::before {
    font-weight: 900;
    content: "\f14a";
    color: #14B8AE;
  }

  .rct-icon-half-check::before {
    opacity: .5;
    content: "\f14a";
  }

  .rct-icon-leaf::before {
    content: "\f15b";
  }

  .rct-icon-parent-open::before {
    content: "\f07c";
  }

  .rct-icon-parent-close::before {
    content: "\f07b";
  }

  .rct-icon-expand-all::before {
    content: "\f0fe";
  }

  .rct-icon-collapse-all::before {
    content: "\f146";
  }
}
